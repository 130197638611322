import request from '@/utils/request';

export function currentUser() {
  return request({
    url: '/auth/get-info',
    method: 'post'
  });
}

export function logout() {
  return request({
    url: '/auth/logout',
    method: 'post'
  });
}

export function login(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data
  });
}

export function postWaitlist(data) {
  return request({
    url: '/application_waitlist',
    method: 'post',
    data
  });
}

export function oneidcode(params) {
  return request({
    url: '/oneidcode',
    method: 'get',
    params
  });
}

export function faceidcode(params) {
  return request({
    url: '/faceidcode',
    method: 'get',
    params
  });
}

export function foreignRegister(data) {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    if(Array.isArray(data[key])){
      data[key].forEach(child => {
        formData.append(`${key}[]`, child);
      });
    }else{
      formData.append(key, data[key]);
    }
  })
  return request({
    headers: {'Content-Type':'multipart/form-data'},
    url: '/member/foreign-register',
    method: 'post',
    data: formData
  });
}

export function changePassword(data) {
  return request({
    url: '/auth/change-password',
    method: 'put',
    data
  });
}

export function forgetPassword(data) {
  return request({
    url: '/member/forget-password',
    method: 'post',
    data
  });
}

export function resetPassword(data) {
  return request({
    url: '/member/reset-password',
    method: 'post',
    data
  });
}

export function regDatePassed() {
  return request({
    url: '/reg-date-passed',
    method: 'get'
  });
}
export function applicationEditDatePassed() {
  return request({
    url: '/application-edit-date-passed',
    method: 'get'
  });
}
export function regDateHackathonPassed() {
  return request({
    url: '/reg-date-hackathon-passed',
    method: 'get'
  });
}
export function passedDates() {
  return request({
    url: '/passed-dates',
    method: 'get'
  });
}
