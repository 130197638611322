import { 
  currentUser, 
  logout, 
  login,
  postWaitlist,
  oneidcode,
  foreignRegister,
  changePassword,
  forgetPassword,
  resetPassword,
  faceidcode,
  regDatePassed,
  applicationEditDatePassed,
  regDateHackathonPassed,
  passedDates
} from '@/api/auth';

import { setToken, setTokenTime, clearForLogout } from '@/utils/auth';

export const actions = {
  currentUser({ commit }) {
    return new Promise((resolve, reject) => {
      currentUser()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_USER', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout()
      .then(res => {
        if (res['success'] && res['data']) {
          clearForLogout();
        }
        resolve(true);
      })
      .catch(err => {
        reject(err);
      });
    });
  },
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      login(data)
        .then(res => {
          if (res['success'] && res['data']) {
            setToken(res['data']['access_token']);
            setTokenTime(res['data']['expires_in']);
            commit('SET_IS_AUTH', true);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  postWaitlist({ commit }, data) {
    return postWaitlist(data);
  },
  oneidcode({ commit }, params) {
    return new Promise((resolve, reject) => {
      oneidcode(params)
        .then(res => {
          if (res['success'] && res['data']) {
            setToken(res['data']['access_token']);
            setTokenTime(res['data']['expires_in']);
            commit('SET_IS_AUTH', true);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  faceidcode({ commit }, params) {
    return new Promise((resolve, reject) => {
      faceidcode(params)
        .then(res => {
          if (res['success'] && res['data']) {
            setToken(res['data']['access_token']);
            setTokenTime(res['data']['expires_in']);
            commit('SET_IS_AUTH', true);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  foreignRegister({ commit }, data) {
    return new Promise((resolve, reject) => {
      foreignRegister(data)
        .then(res => {
          if (res['success'] && res['data']) {
            setToken(res['data']['access_token']);
            setTokenTime(res['data']['expires_in']);
            commit('SET_IS_AUTH', true);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  changePassword({ commit }, data) {
    return changePassword(data);
  },
  forgetPassword({ commit }, data) {
    return forgetPassword(data);
  },
  resetPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      resetPassword(data)
        .then(res => {
          if (res['success'] && res['data']) {
            setToken(res['data']['access_token']);
            setTokenTime(res['data']['expires_in']);
            commit('SET_IS_AUTH', true);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  checkRegDatePassed({ commit }) {
    return new Promise((resolve, reject) => {
      regDatePassed()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_REG_DATE_PASSED', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  checkApplicationEditDatePassed({ commit }) {
    return new Promise((resolve, reject) => {
      applicationEditDatePassed()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_APPLICATION_EDIT_DATE_PASSED', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  checkRegDateHackathonPassed({ commit }) {
    return new Promise((resolve, reject) => {
      regDateHackathonPassed()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_REG_DATE_HACKATHON_PASSED', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getPassedDates({ commit }) {
    return new Promise((resolve, reject) => {
      passedDates()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_PASSED_DATES', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
