import { setItem } from '@/utils/storage';

export const mutations = {
  SET_USER: (state, data) => {
    state.user = data;
    if (state.user) {
      state.role_name = state.user.role;
      setItem('user', state.user);
    }
  },
  SET_IS_AUTH: (state, value) => {
    state.is_auth = value;
  },
  SET_REG_DATE_PASSED: (state, value) => {
    state.reg_date_passed = value;
  },
  SET_APPLICATION_EDIT_DATE_PASSED: (state, value) => {
    state.applicationEditDatePassed = value;
  },
  SET_REG_DATE_HACKATHON_PASSED: (state, value) => {
    state.regDateHackathonPassed = value;
  },
  SET_PASSED_DATES: (state, value) => {
    state.passedDates = value;
  },
};