import { 
  list,
  indicators,
  addBalls
} from '@/api/checkpoints';

export const actions = {
  getList({ commit },params) {
    return new Promise((resolve, reject) => {
      list(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getIndicators({ commit },params) {
    return indicators(params);
  },
  addBalls({ commit },params) {
    return addBalls(params);
  },
};
