import { getToken } from '@/utils/auth'
import { getItem } from '@/utils/storage'

let user = getItem('user');
export const state = {
  is_auth: getToken()?true:false,
  user: user,
  role_name: user?user.role:"",
  reg_date_passed: false,
  applicationEditDatePassed: false,
  regDateHackathonPassed: false,
  passedDates: {}
}
