import { 
  memberCreate,
  sendphone,
  verifyphone,
  sendEmail,
  list,
  showById,
  confirm,
  revise,
  sendPending,
  editForeign,
  editLocal,
  getUnreadCount,
  downloadList,
  sendSms,
  getHackathonCertificate,
  getPdfFile
} from '@/api/member';

export const actions = {
  sendSms({ commit }, data) {
    return sendSms(data);
  },
  memberCreate({ commit }, data) {
    return memberCreate(data);
  },
  showById({ commit }, id) {
    return showById(id);
  },
  confirm({ commit }, id) {
    return confirm(id);
  },
  revise({ commit }, data) {
    return revise(data);
  },
  sendPending({ commit }) {
    return sendPending();
  },
  editForeign({ commit },data) {
    return editForeign(data);
  },
  editLocal({ commit },data) {
    return editLocal(data);
  },
  getList({ commit },params) {
    return new Promise((resolve, reject) => {
      list(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  sendphone({ commit }, data) {
    return sendphone(data);
  },
  verifyphone({ commit }, data) {
    return verifyphone(data);
  },
  sendEmail({ commit }, data) {
    return sendEmail(data);
  },
  getUnreadCount({ commit }) {
    return new Promise((resolve, reject) => {
      getUnreadCount()
        .then(res => {
          if (res['success']) {
            commit('SET_UNREAD_COUNT', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  downloadList({ commit }, params) {
    return downloadList(params);
  },
  getHackathonCertificate({ commit }, uuid) {
    return getHackathonCertificate(uuid);
  },
  getPdfFile({ commit }, params) {
    return getPdfFile(params);
  },
};
