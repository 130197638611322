import request from '@/utils/request';

export function list(params) {
  return request({
    url: '/checkpoints',
    method: 'get',
    params
  });
}
export function indicators(params) {
  return request({
    url: '/checkpoints/indicators',
    method: 'get',
    params
  });
}
export function addBalls(data) {
  return request({
    url: '/checkpoints/add-balls',
    method: 'post',
    data
  });
}